import React from "react"
import s from "./Testimony.module.scss"

function Testimony({ date, from, testimony, title }) {
    return (
        <div className={s.testimonyContainer}>
            <h3>{title}</h3>
            <p className={s.testimony}>{testimony}</p>
            <ul className={s.metadata}>
                <li className={s.from}>{from}</li>
                <li className={s.date}>{date}</li>
            </ul>
        </div>
    )
}

export default Testimony
