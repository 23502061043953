import React from "react"
import s from "./testimonials.module.scss"
import Layout from "../layout"
import ContentContainer from "../../atoms/ContentContainer"
import Heading from "../../atoms/Heading"
import Testimony from "../../organisms/Testimony"

function Testimonials({ pageContext }) {
    return (
        <Layout>
            <ContentContainer>
                <div className={s.pageIntro}>
                    <Heading>{pageContext.title}</Heading>
                    {/* <Subheading>{pageContext.description}</Subheading> */}
                    <hr />
                    {pageContext.testimonials.map((testimony, index) => (
                        <Testimony {...testimony} />
                    ))}
                </div>
            </ContentContainer>
        </Layout>
    )
}

export default Testimonials
